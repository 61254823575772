import React, { useCallback, useEffect, useState } from 'react';
import type { FC } from 'react';
import {
  Container,
  Typography,
  Card,
  CardActionArea,
  CardContent,
  ImageList,
  ImageListItem,
  Box,
  Link,
  Stack,
  Theme,
  useMediaQuery
} from '@mui/material';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { BrancheListDTO } from 'src/api/dto/organization/brancheList';
import useSession from 'src/hooks/useSession';
import axios from 'src/lib/axios';
import { Link as RouterLink } from 'react-router-dom';
import { Branche } from 'src/types/branche';
import { branches } from 'src/dummydata/branches';
import useSettings from 'src/hooks/useSettings';

const splitName = (name: string, onlyFirst: boolean, sep: string = ' ') => {
  if (name) {
    const result = name.split(sep);

    if (!onlyFirst) {
      result.shift();
      return result.join(sep);
    }

    return result[0];
  }

  return '';
};

const Categories: FC = () => {
  const isMountedRef = useIsMountedRef();
  const { settings } = useSettings();
  const { session, saveSession } = useSession();
  const [listBranchen, setListBranchen] = useState<Branche[] | null>(
    null
  );
  const breakpointSXOnly = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));

  let brancheResponse: Branche[];
  const getListBranchen = useCallback(async () => {
    try {
      const response = await axios.get<BrancheListDTO[]>(
        '/api/organization/listofbranches'
      );
      if (isMountedRef.current) {
        brancheResponse = response.data;
        brancheResponse.forEach((item, index) => {
          const branch = branches.find((x) => x.id === item.id);
          if (branch) {
            brancheResponse[index].color = branch.color;
            brancheResponse[index].left = branch.left;
            brancheResponse[index].name2pl = branch.name2pl;
            brancheResponse[index].picture = branch.picture;
            brancheResponse[index].rotate = branch.rotate;
            brancheResponse[index].top = branch.top;
            brancheResponse[index].name = branch.name;
            brancheResponse[index].smallFont = branch.smallFont;
          } else {
            console.warn(`Branch with id ${item.id}not found.`);
          }
        });
        setListBranchen(brancheResponse);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  useEffect(() => {
    getListBranchen();
  }, [getListBranchen]);

  if (!listBranchen) {
    return null;
  }

  const handleCategoryClick = (checkedKey: string) => {
    const sessionData = session;
    sessionData.selectedSearchMode = checkedKey;
    saveSession({
      ...session
    });
    window.scrollTo(0, 0);
  };

  return (
    <Box sx={{ display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: 'white' }}
    >
      <Container
        maxWidth={settings.compact ? 'xl' : false}
        sx={{ paddingTop: 5, paddingBottom: 5, paddingRight: 0, paddingLeft: 0 }}
      >
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          spacing={2}
        >
          <Box sx={{ ...(!breakpointSXOnly && { width: 220 }), paddingLeft: 1, paddingRight: 1 }}>
            <Typography
              variant="h4"
            >
              Durchstöbere unsere Kategorien
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontWeight: 'normal', paddingTop: 2, paddingBottom: 2 }}
            >
              und finde Dienstleister ganz in deiner Nähe
            </Typography>
            {/* / <Button variant="contained">alle Kategorien anzeigen</Button> */ }
          </Box>
          <ImageList
            sx={{ flexWrap: 'nowrap',
              // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
              transform: 'translateZ(0)',
              display: 'flex',
              flexDirection: 'row' }}
          >
            {listBranchen.map((branch) => (
              <Link
                key={branch.id}
                color="textSecondary"
                component={RouterLink}
                to="/"
                underline="none"
                variant="body2"
              >
                <ImageListItem
                  key={branch.id}
                >
                  <Card
                    sx={{ boxShadow: 'none' }}
                  >
                    <CardActionArea>
                      <CardContent
                        onClick={() => handleCategoryClick(branch.name)}
                      >
                        <Box sx={{ height: 240, backgroundColor: branch.color, borderRadius: 5, width: 165, marginLeft: 5, boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px;;' }}>
                          <Typography
                            gutterBottom
                            variant={branch?.smallFont ? 'subtitle1' : 'h5'}
                            sx={{ paddingLeft: 2, paddingTop: 2, color: 'white', position: 'relative', zIndex: 1, textShadow: '1px 1px #777a7b;', fontWeight: 'bold' }}
                          >
                            {splitName(branch.name, true)}
                          </Typography>
                          <Typography
                            gutterBottom
                            variant={branch?.smallFont ? 'subtitle1' : 'h5'}
                            sx={{ paddingLeft: `${branch.name2pl * 8}px`, marginTop: -1, color: 'white', position: 'relative', zIndex: 1, textShadow: '1px 1px #777a7b;', fontWeight: 'bold' }}
                          >
                            {splitName(branch.name, false)}
                          </Typography>
                        </Box>
                        <Box
                          component="img"
                          sx={{
                            top: branch.top,
                            left: branch.left,
                            height: 200,
                            position: 'absolute',
                            transform: `rotate(${branch.rotate}deg);`,
                          }}
                          src={branch.picture}
                        />
                      </CardContent>
                    </CardActionArea>
                  </Card>
                  )
                </ImageListItem>
              </Link>
            ))}
          </ImageList>
        </Stack>
      </Container>
    </Box>
  );
};

export default Categories;
