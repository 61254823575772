/* eslint-disable no-underscore-dangle */
import { useCallback, useEffect, useState } from 'react';
import type { FC } from 'react';
import {
  Container,
  Typography,
  Box,
  Stack,
  Theme,
  useMediaQuery } from '@mui/material';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import axios from 'axios';
import { serverConfig } from 'src/config';
import { KumaoTheme } from 'src/theme';
import useSettings from 'src/hooks/useSettings';
import { OrganizationSearchResponse } from 'src/api/dto/search/OrganizationSearchResponse';
import NewcomerSlider from './NewcomerSlider';

const Newcomer: FC = () => {
  const isMountedRef = useIsMountedRef();
  const { settings } = useSettings();
  const [organizations, setOrganizations] = useState<OrganizationSearchResponse | null>(
    null
  );
  const breakpointSXOnly = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));

  const getOrganizations = useCallback(async () => {
    const query = {
      query: {
        match_all: {}
      },
      size: 5,
      from: 0,
      sort: [
        {
          first_activation_date: {
            order: 'desc'
          }
        }
      ]
    };
    try {
      const instance = axios.create();
      delete instance.defaults.headers.common.Authorization;
      instance.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';
      const response = await instance.post<OrganizationSearchResponse>(
        serverConfig.searchUrl, JSON.stringify(query), {
          auth: {
            username: 'readall',
            password: 'readonly'
          } }
      );
      if (isMountedRef.current) {
        console.group('SEARCH');
        console.log(response.data);
        console.groupEnd();
        response.data.hits.hits.map((searchObj, i) => (
          response.data.hits.hits[i]._source.branches = JSON.parse(searchObj._source.branchesjson)
        ));
        setOrganizations(response.data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  useEffect(() => {
    getOrganizations();
  }, [getOrganizations]);

  if (!organizations) {
    return null;
  }

  return (
    <Box sx={{
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: (theme: KumaoTheme) => theme.palette.background.mintgreen }}
    >
      <Container
        maxWidth={settings.compact ? 'xl' : false}
        disableGutters
        sx={{ paddingTop: 5, paddingBottom: 5, paddingRight: 0, paddingLeft: 3, ...(breakpointSXOnly && { paddingLeft: 0 }) }}
      >
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          spacing={2}
        >
          <Box sx={{ ...(!breakpointSXOnly && { width: 220 }), paddingLeft: 1, paddingRight: 1 }}>
            <Typography
              variant="h4"
            >
              Newcomer auf Kumao
            </Typography>
          </Box>
          <NewcomerSlider organizations={organizations} />
        </Stack>
      </Container>
    </Box>
  );
};

export default Newcomer;
