import type { FC } from 'react';
import {
  Box,
  Container,
  Typography,
  Button,
  Link,
  Grid,
  Card,
  Avatar
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Link as RouterLink } from 'react-router-dom';
import { KumaoTheme } from 'src/theme';
import useSettings from 'src/hooks/useSettings';
import Banner from '../widgets/userMessage/Banner';

interface PromotionProps {}

const Promotion: FC<PromotionProps> = () => {
  const { settings } = useSettings();

  const NumberedIconButton = (value: { index:string }) => {
    const { index } = value;
    console.log(value);
    return (
      <Avatar
        sx={{
          height: 42,
          width: 42,
          margin: 0.9,
          bgcolor: 'white'
        }}
      >

        <Typography
          sx={{
            color: (theme: KumaoTheme) => theme.palette.secondary.light
          }}
          fontSize={22}
          fontWeight={700}
        >
          {index}
        </Typography>
      </Avatar>
    );
  };

  return (
    <Box sx={{ backgroundColor: (theme: KumaoTheme) => theme.palette.background.paper }}>
      <Container
        maxWidth={settings.compact ? 'lg' : false}
        sx={{ paddingTop: 5 }}
      >
        <Box mt={3}>
          <Typography
            variant="h4"
            align="center"
            sx={{ color: (theme: KumaoTheme) => theme.palette.primary.main }}
          >
            DU MÖCHTEST EINER DER ERSTEN DIENSTLEISTER AUF KUMAO SEIN?
          </Typography>
        </Box>
        <Box mt={3}>
          <Typography
            align="center"
            variant="h5"
          >
            Registriere dich jetzt und werde Teil von Kumao
          </Typography>
        </Box>
        { false && (
        <Banner sx={{ mt: 2 }}>
          <Typography
            fontSize={17}
            fontWeight={700}
          >
            Registriere dich noch bis Jahresende und erhalte zusätzlich zu den 3 Monaten Testzeitraum, weitere 2 Monate geschenkt.
          </Typography>
        </Banner>
        )}
        <Grid
          container
          spacing={2}
          sx={{ pt: 3 }}
        >
          <Grid
            item
            sm={12}
            md={6}
          >
            <Card
              color="primary"
              sx={{
                flexGrow: 1,
                overflow: 'hidden',
                px: 3,
                pt: 1,
                backgroundColor: (theme: KumaoTheme) => theme.palette.secondary.light,
                borderRadius: 5
              }}
            >
              <Typography
                sx={{
                  color: (theme: KumaoTheme) => theme.palette.secondary.contrastText, fontSize: 22, fontWeight: 700
                }}
              >
                In nur 3 Schritten zu deinem Unternehmensprofil:
              </Typography>
              <Box sx={{ my: 1, mx: 'auto', p: 2 }}>
                <Grid
                  container
                  wrap="nowrap"
                  spacing={2}
                >
                  <Grid item>
                    <NumberedIconButton index="1" />
                  </Grid>
                  <Grid
                    item
                    xs
                    zeroMinWidth
                    sx={{ my: 'auto' }}
                  >
                    <Typography
                      sx={{
                        color: (theme: KumaoTheme) => theme.palette.secondary.contrastText, fontSize: 18, fontWeight: 700
                      }}
                    >
                      Registriere dich auf Kumao und nutze Kumao für die ersten 3 Monate kostenlos.
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box
                sx={{
                  my: 1,
                  mx: 'auto',
                  p: 2
                }}
              >
                <Grid
                  container
                  wrap="nowrap"
                  spacing={2}
                >
                  <Grid item>
                    <NumberedIconButton index="2" />
                  </Grid>
                  <Grid
                    item
                    xs
                    sx={{ my: 'auto' }}
                  >
                    <Typography
                      sx={{
                        color: (theme: KumaoTheme) => theme.palette.secondary.contrastText, fontSize: 18, fontWeight: 700
                      }}
                    >
                      Erstelle dein Unternehmensprofil für deine Kunden.
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box
                sx={{
                  my: 1,
                  mx: 'auto',
                  p: 2,
                }}
              >
                <Grid
                  container
                  wrap="nowrap"
                  spacing={2}
                >
                  <Grid item>
                    <NumberedIconButton index="3" />
                  </Grid>
                  <Grid
                    item
                    xs
                    sx={{ my: 'auto' }}
                  >
                    <Typography
                      sx={{
                        color: (theme: KumaoTheme) => theme.palette.secondary.contrastText, fontSize: 18, fontWeight: 700
                      }}
                    >
                      Lege deine Services, Events und Kurse an, die deine Kunden online buchen können.
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Grid>
          {/* ---------------- second card -------------- */}
          <Grid
            item
            sm={12}
            md={6}
          >
            <Card
              color="primary"
              sx={{
                flexGrow: 1,
                overflow: 'hidden',
                px: 3,
                pt: 1,
                backgroundColor: (theme: KumaoTheme) => theme.palette.primary.main,
                borderRadius: 5
              }}
            >
              <Typography
                sx={{
                  color: (theme: KumaoTheme) => theme.palette.secondary.contrastText, fontSize: 22, fontWeight: 700
                }}
              >
                Deine Vorteile auf Kumao:
              </Typography>
              <Box sx={{ my: 1, mx: 'auto', p: 2 }}>
                <Grid
                  container
                  wrap="nowrap"
                  spacing={2}
                >
                  <Grid item>
                    <CheckCircleIcon
                      sx={{
                        color: (theme: KumaoTheme) => theme.palette.primary.contrastText,
                        fontSize: '50px'
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs
                    zeroMinWidth
                    sx={{ my: 'auto' }}
                  >
                    <Typography
                      sx={{
                        color: (theme: KumaoTheme) => theme.palette.primary.contrastText, fontSize: 18, fontWeight: 700
                      }}
                    >
                      Kunden können schnell und einfach zu jederzeit deine Dienstleistungen buchen!
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box
                sx={{
                  my: 1,
                  mx: 'auto',
                  p: 2
                }}
              >
                <Grid
                  container
                  wrap="nowrap"
                  spacing={2}
                >
                  <Grid item>
                    <CheckCircleIcon
                      sx={{
                        color: (theme: KumaoTheme) => theme.palette.primary.contrastText,
                        fontSize: '50px'
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs
                    sx={{ my: 'auto' }}
                  >
                    <Typography
                      sx={{
                        color: (theme: KumaoTheme) => theme.palette.primary.contrastText, fontSize: 18, fontWeight: 700
                      }}
                    >
                      Werde gefunden, präsentiere dein Unternehmen, binde Kunden an dich!
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box
                sx={{
                  my: 1,
                  mx: 'auto',
                  p: 2,
                }}
              >
                <Grid
                  container
                  wrap="nowrap"
                  spacing={2}
                >
                  <Grid item>
                    <CheckCircleIcon
                      sx={{
                        color: (theme: KumaoTheme) => theme.palette.primary.contrastText,
                        fontSize: '50px'
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs
                    sx={{ my: 'auto' }}
                  >
                    <Typography
                      sx={{
                        color: (theme: KumaoTheme) => theme.palette.primary.contrastText, fontSize: 18, fontWeight: 700
                      }}
                    >
                      Gebuchte Termine und freie Slots immer im Überblick!
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Grid>
        </Grid>

        {/*
        <Box
          sx={{ width: '80%', margin: 'auto' }}
        >
          <Box
            mt={3}
            justifyContent="center"
            sx={{
              backgroundColor: (theme: KumaoTheme) => theme.palette.background.dark,
              position: 'relative',
              paddingTop: '56.25%',
            }}
          >
            <iframe
              style={{ position: 'absolute', top: 0, left: 0, height: '100%', width: '100%' }}
              frameBorder="0"
              allowFullScreen
              src="https://www.youtube.com/embed/DXIsTTH2wzg"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            />
          </Box>
        </Box>
        */}
        <Box
          mt={3}
          pb={3}
        >
          <Link
            color="textSecondary"
            component={RouterLink}
            to="/register"
            underline="none"
            variant="body2"
          >
            <Button
              variant="outlined"
              sx={{ width: '100%' }}
              color="primary"
            >
              Jetzt kostenlos registrieren
            </Button>
          </Link>
        </Box>
      </Container>
    </Box>
  );
};

export default Promotion;
